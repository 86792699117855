import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import maps from './maps.mjs'

export default defineAppConfig({
  excludeRegionIds: ['999'],
  filters: [],
  ...maps,
  pickupListMaxLength: 200,
  pickupListPageSize: 20,
  ...config,
  createPickupPlaceTitleFromAddress: ({ street, city }) => [street, city].join(', '),
})
