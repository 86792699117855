export default {
  category: {
    filters: {
      defaultMaxPrice: 100,
      defaultPriceFieldName: 'final_price',
    },
    meiro: {
      baseUrl: 'https://cdp.drmax.meiro.io',
    },
    optimize: {
      meiroTest: 'CRPvKQ4VSVSCJ7dx3etZHA',
    },
    root: {
      id: 1269,
    },
    sponsoredProductsLabel: true,
    sponsoredSources: [
      'cxsg',
    ],
  },
}
