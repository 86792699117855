import { field, Mutation, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartShippingAddress from '../fragments/cartShippingAddress.js'
import cartBillingAddress from '../fragments/cartBillingAddress.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import marketplaceQuote from '../fragments/marketplaceQuote.js'

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Mutation.named('mergeCarts')
  .where('source_cart_id', variable('sourceCartId', REQUIRED_STRING))
  .where('destination_cart_id', variable('destinationCartId', REQUIRED_STRING))
  .fields([
    ...cartItemsModified,
    cartPrices,
    field('email'),
    cartBillingAddress,
    cartShippingAddress,
    cartAvailablePaymentMethods,
    cartAppliedPaymentRestrictions,
    cartSelectedPaymentMethod,
    ...marketplaceEnabled ? [marketplaceQuote] : [],
  ])
