import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import catalog from './catalog.mjs'
import category from './category.mjs'
import features from './features.mjs'
import product from './product.mjs'
import tags from './tags.mjs'

export default defineAppConfig({
  ...catalog,
  ...category,
  ...features,
  ...product,
  ...tags,
})
