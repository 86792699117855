export default `<!DOCTYPE html>
<html lang="en" class="no-js">
<head>
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover" />
    <link rel="stylesheet" media="all" href="/css/global.min.css" id="global-styles" />
    <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
    <link rel="manifest" href="/favicons/site.webmanifest" />
    <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#288c00" />
    <link rel="shortcut icon" href="/favicons/favicon.ico" />
    <meta name="msapplication-TileColor" content="#288c00" />
    <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
    <meta name="theme-color" content="#288c00" />
    <title>Dr. Max lékárna - Nepodporovaný prohlížeč</title>
    <meta content="noindex,nofollow" name="robots" />
    <meta name="description" content="popis vaseho webu" />
  </head>
</head>
<body>
<div class="page" id="js-page">
  <main class="page__content">
    <div class="container">
      <section class="error-page error-page--500">
        <img src="/images/error-pages/500-page@1000.png" alt="" />
        <h1>Aktualizujte si prohlížeč</h1>
        <p class="m-0">
          Stránky Dr.Max jsou postaveny na moderních technologiích, které Vám přináší vyšší bezpečnost nákupu, rychlost, komfort a lepší uživatelský zážitek.
        </p>
        <p>
          Bohužel používáte velmi starý prohlížeč, který již není podporován.
        </p>
        <p>
          Na opravě pro podporu prohlížeče <strong>Internet Explorer 11</strong> intenzivně pracujeme.
          Omlouváme se za komplikace.
        </p>
        <p class="text-success">
          <strong>
            Abyste mohli pokračovat, nainstalujte si libovolný z těchto prohlížečů, které jsou zdarma a v češtině.
          </strong>
        </p>
        <div id="pc" class="d-none">
          <a class="btn btn-outline-primary" href="https://www.google.com/chrome/">
            Chrome
          </a>
          <a class="btn btn-outline-primary" href="https://www.mozilla.org/cs/firefox/new/">
            Firefox
          </a>
        </div>
        <div id="mobile" class="d-none">
          <a
            class="btn btn-outline-primary"
            href="https://play.google.com/store/apps/details?id=com.android.chrome"
          >
            Chrome
          </a>
          <a
            class="btn btn-outline-primary"
            href="https://play.google.com/store/apps/details?id=org.mozilla.firefox"
          >
            Firefox
          </a>
        </div>
      </section>
    </div>
  </main>
</div>

<script type="text/javascript">
  (function(window, document) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)
    ) {
      document.getElementById("mobile").classList.replace("d-none", "d-block")
    } else {
      document.getElementById("pc").classList.replace("d-none", "d-block")
    }
  })(window, document)
</script>
</body>
</html>
`