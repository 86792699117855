import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { getPageById } from '@nsf/cms/repositories/CmsServiceRepository.js'

export default function () {
  registerPageResolver(
    'CMS_SERVICE_PAGE',
    getPageById,
    () => import('@nsf/cms/dynamicPages/PageDetail.vue'),
  )
}
