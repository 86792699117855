import { mapDataToModel, withDefault } from '@nsf/core/Mapper.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { getDefaultPrices } from '../repositories/CartRepositoryDefaults.js'
import mapDataToItem from './ItemMapper.js'
import mapDataToGiftCard from './GiftCardMapper.js'
import { mapDataToSalesRule } from './SalesRulesMapper.js'
import { mapDepositFeePrice } from './DepositFeeMapper.js'
import mapDataToPrices from './PricesMapper.js'
import mapDataToItemGroups from './CartGroupsMapper.js'
import mapItemsToSellersGroup from './SellersGroupMapper.js'
import { mapSellersPrices } from './SellersPricesMapper.js'
import mapSellers from './CartSellersMapper.js'

const {
  checkout: {
    features: {
      boundProductsEnabled,
      reservationsIgnoreStockStatusEnabled,
    },
  },
} = useAppConfig()

const isTrueOrNotSet = (value) => [true, null, undefined].includes(value)

const getReservationAllowedByItem = (item) => {
  const {
    product: { drmaxActionAllowReserve, drmaxPromoAllowReserve, specialPrice },
  } = item

  const isAllowedByAction = specialPrice ? isTrueOrNotSet(drmaxActionAllowReserve) : true
  const isAllowedByPromo = isTrueOrNotSet(drmaxPromoAllowReserve)

  return isAllowedByAction && isAllowedByPromo
}

const getReservationAllowedByItems = (items) => items.every(getReservationAllowedByItem)

const getReservationAllowed = (items, salesRules) => {
  const isAllowedByItems = getReservationAllowedByItems(items)
  const isAllowedBySalesRules = salesRules.every(({ reservationAllowed }) => reservationAllowed)
  const isAllowed = isAllowedByItems && isAllowedBySalesRules

  return reservationsIgnoreStockStatusEnabled || isAllowed
}

// TODO(63207): Split this into smaller mappers.
// TODO(63207): Rename.
export const mapDataToItemsPricesAndCoupons = (response) => {
  const {
    cart,
  } = response

  const items = mapDataToItem(cart.items ?? [])

  const appliedCoupons = cart.applied_coupons?.map(({ code, units, value }) => ({
    code,
    units,
    value,
  })) ?? []

  const appliedGiftCards = cart.applied_gift_cards ? mapDataToGiftCard(cart.applied_gift_cards) : []
  const appliedSalesRules = cart.applied_sales_rules ? mapDataToSalesRule(cart.applied_sales_rules) : []
  const loyaltyProgram = cart.loyalty_programme ? mapDataToModel(cart.loyalty_programme) : {}
  const freeShipping = cart.free_shipping ? mapDataToModel(cart.free_shipping) : {}

  const isReservationAllowed = getReservationAllowed(items, appliedSalesRules)

  const groups = mapDataToItemGroups(items, appliedSalesRules)
  const itemGroups = boundProductsEnabled ? mapDepositFeePrice(groups, cart.bound_products) : groups

  const has3rdPartyProduct = cart.drmax_quote_type === 'mixed' || cart.drmax_quote_type === 'marketplace'
  const hasDrMaxAndMarketplaceProducts = cart.drmax_quote_type === 'mixed'
  const isOnlyMarketplaceCart = cart.drmax_quote_type === 'marketplace'

  const marketplaceItemGroups = has3rdPartyProduct ? mapItemsToSellersGroup(itemGroups) : []

  const sellersPrices = mapSellersPrices(cart.marketplace_quote ?? {}, cart.drmax_quote_type)

  const sellers = mapSellers(items)

  return {
    appliedCoupons,
    appliedGiftCards,
    freeShipping: {
      ...freeShipping,
      salesRule: cart.applied_sales_rules?.find((rule) => rule.freeShipping),
    },
    has3rdPartyProduct,
    hasDrMaxAndMarketplaceProducts,
    isOnlyMarketplaceCart,
    isReservationAllowed,
    itemGroups,
    loyaltyProgram,
    marketplaceItemGroups,
    marketplacePrices: withDefault(
      getDefaultPrices,
      mapDataToPrices(cart.marketplace_quote?.marketplace_quote_totals?.marketplace_quote_prices ?? {}),
    ),
    firstPartyMarketplacePrices: withDefault(
      getDefaultPrices,
      mapDataToPrices(cart.marketplace_quote?.marketplace_quote_totals?.one_p_quote_prices ?? {}),
    ),
    onlineTotal: cart.marketplace_quote?.marketplace_quote_totals?.payment_summary?.online_total?.value ?? 0,
    offlineTotal: cart.marketplace_quote?.marketplace_quote_totals?.payment_summary?.offline_total?.value ?? 0,
    prices: withDefault(getDefaultPrices, mapDataToPrices(cart.prices)),
    sellersPrices,
    sellers,
  }
}

export const mapCartItemsToMeiroCheckoutData = (itemGroups) => {
  const mapProductThumbnailToMeiroCheckoutData = (thumbnail) => ({
    url: thumbnail.url ?? null,
  })

  const mapProductCategoryToMeiroCheckoutData = (category) => ({
    // eslint-disable-next-line camelcase
    url_key: category.urlKey ?? null,
    name: category.name ?? null,
  })

  const mapProductPriceToMeiroCheckoutData = (amount) => ({
    amount: {
      currency: amount.currency ?? null,
      value: amount.value ?? null,
    },
  })

  const mapProductToMeiroCheckoutData = (product) => ({
    sku: product.sku ?? null,
    name: product.name ?? null,
    // eslint-disable-next-line camelcase
    url_key: product.urlKey ?? null,
    thumbnail: mapProductThumbnailToMeiroCheckoutData(product.thumbnail),
    categories: (product.categories ?? []).map((c) => mapProductCategoryToMeiroCheckoutData(c)),
    price: mapProductPriceToMeiroCheckoutData(product.price?.regularPrice?.amount ?? null),
    // eslint-disable-next-line camelcase
    special_price: product.specialPrice ?? null,
  })

  return itemGroups.flatMap((groupItem) => (groupItem.items ?? []).map((item) => ({
    id: item.id ?? null,
    stock: item.stock ?? null,
    quantity: item.quantity ?? null,
    product: mapProductToMeiroCheckoutData(item.product),
  })))
}
