import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import delivery from './delivery.mjs'
import features from './features.mjs'
import pharmacies from './pharmacies.mjs'

export default defineAppConfig({
  ...delivery,
  ...features,
  ...pharmacies,
})
