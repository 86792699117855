export default {
  product: {
    alternateProductSeoDescription: false,
    defaultMaxQty: 1000,
    detail: {
      disableReviewSource: true,
      addAttributeSetToAttributes: false,
      apothekaWidgetEnabled: true,
      showLastUpdate: false,
      showLoggedPriceNote: false,
      showPriceInfo: true,
      showPriceNote: false,
      titleRowsSeparated: false,
      useFreeShippingThreshold: true,
      showFreeShippingValue: false,
    },
    infoBoxEnabled: false,
    isAttributeSetShowed: true,
    reviewsEnabled: true,
    shortDescriptionShowedOn: [
      'category',
      'eLeader',
      'productDetail',
      'single',
      'topProducts',
    ],
    wrapperProductPimFilter: [
      'Temporary unavailable',
      'Visible',
      'Permanently unavailable',
      'Delisted - sold off',
      'Promo',
    ],
  },
}
