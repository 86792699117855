import { registerPageResolver } from '@nsf/core/PageResolver.js'
import { getLinkTreeById } from '@nsf/cms/repositories/CmsServiceRepository.js'

export default function () {
  registerPageResolver(
    'CMS_SERVICE_LINK_TREE',
    getLinkTreeById,
    () => import('@nsf/cms/dynamicPages/CmsLinkTree.vue'),
  )
}
