/* eslint-disable camelcase */
import { withCommonMapProxyShippingMethod } from '@nsf/checkout/mappers/CartMapper.js'
import mapDataToPaymentMethod from '@nsf/checkout/mappers/PaymentMethodMapper.js'
import { mapDataToShippingMethod } from '@nsf/checkout/mappers/ShippingMethodMapper.js'
import { getDefaultPaymentMethod } from '@nsf/checkout/repositories/PaymentMethodRepositoryDefaults.js'
import {
  getDefaultShippingMethod,
} from '@nsf/checkout/repositories/ShippingMethodRepositoryDefaults.js'
import { withDefault } from '@nsf/core/Mapper.js'
import { isArray } from '@nsf/core/Utils.js'

export const mapDataToFavoritePaymentMethods = (data = []) => {
  if (!isArray(data)) {
    return []
  }

  const paymentMethods = withDefault(
    getDefaultPaymentMethod,
    mapDataToPaymentMethod(data),
  )

  return paymentMethods || []
}

export const mapDataToFavoriteShippingMethods = (data = []) => {
  if (!isArray(data)) {
    return []
  }

  let shippingMethods = withDefault(
    getDefaultShippingMethod,
    mapDataToShippingMethod(data),
  )

  shippingMethods = withCommonMapProxyShippingMethod(shippingMethods)

  return shippingMethods || []
}

export const mapDataToFavoritePaymentMethod = (data = {}) => ({
  code: data?.code || '',
})

export const mapDataToFavoriteShippingMethod = (data = {}) => ({
  methodCode: data?.method_code || '',
  carrierCode: data?.carrier_code || '',
  pickupPlaceId: data?.pickup_place_id || '',
  externalId: data?.external_id || '',
})

export const mapFavoriteShippingMethodToData = (shippingMethod) => ({
  method_code: shippingMethod?.methodCode || '',
  carrier_code: shippingMethod?.carrierCode || ' ',
  pickup_place_id: shippingMethod?.pickupPlaceId || '',
  external_id: shippingMethod?.externalId || '',
})

export const mapFavoritePaymentMethodToData = (paymentMethod) => ({
  code: paymentMethod.code || '',
})
