/* eslint-disable camelcase */

export default {
  shippingIcons: {
    zitecDpd: '/img/delivery/dpd.svg',
    sameday: '/img/delivery/sameday.svg',
    drmaxskshippingpostsk: '/img/delivery/post-sk.svg',
    drmaxskshippingpostskpickup: '/img/delivery/post-sk.svg',
    drmaxshippingczechpost: '/img/delivery/post-cz.svg',
    drmaxskshippingupssk: '/img/delivery/sps.svg',
    drmaxclickandcollectshipping: '/img/delivery/cac.svg',
    drmaxshippingppl: '/img/delivery/ppl.svg',
    drmaxshippingdpd: '/img/delivery/dpd.svg',
    drmaxshippingdhl: '/img/delivery/dhl.svg',
    postis: '/img/delivery/truck.svg',
    drmaxshippingpacketa: '/img/delivery/packeta.svg',
    drmaxshippingpacketahomedelivery: '/img/delivery/packeta.svg',
    drmaxpickupplace: '/img/delivery/box.svg', // default for pickup place methods (when no icon specified in BE)
    drmaxshippinggsped: '/img/delivery/truck.svg',
    drmaxtimeslotdelivery: '/img/delivery/dodo.svg',
    drmaxclickandcollectexpress: '/img/delivery/cac.svg',
    drmaxskshippingdrivein: '/img/delivery/cac.svg',
    drmaxskshippinggo4: '/img/delivery/go4.svg',
    innoship: '/img/delivery/truck.svg',
    drmaxshippingpharmalink: '/img/delivery/pharmalink.svg',
    default: '/img/delivery/truck.svg',
  },

  paymentIcons: {
    banktransfer: '/img/payment/bank-transfer.svg',
    checkmo: '/img/payment/upon-delivery.svg',
    cashondelivery: '/img/payment/upon-delivery.svg',
    csob_gateway: '/img/payment/benefit-card.svg',
    stripe_gateway: '/img/payment/card.svg',
    csob2_gateway: '/img/payment/edenred.svg',
    ing_gateway: '/img/payment/card.svg',
    vubeplatby_gateway: '/img/payment/vub.svg',
    tatrapay_gateway: '/img/payment/tatra-pay.svg',
    sporopay_gateway: '/img/payment/sporo-pay.svg',
    edenred: '/img/payment/edenred.svg',
    benefit: '/img/payment/benefit-plus.svg',
    viamo_gateway: '/img/payment/viamo.svg',
    payu_gateway: '/img/payment/payu.svg',
    unicredit_gateway: '/img/payment/unicredit.svg',
    paypal_gateway: '/img/payment/paypal.svg',
    przelewy24: '/img/payment/paypro.svg',
    satispay_gateway: '/img/payment/satispay.jpg',
  },
}
