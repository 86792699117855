import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  codes: [
    '00213049849',
    '29995053000',
    '04995082000',
    '00203257854',
  ],
  medicalProdIds: [13, 15],
  vetProdIds: [19],
})
