import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'
import features from './features.mjs'

export default defineAppConfig({
  ...features,
  apiKey: '150ccdd91db0c2243953e44665f98374',
  analyticsApiKey: '1a9c435d9bb7351ec485982c029ed983',
  ...config,
})
