import { isString, isEmpty } from '@nsf/core/Utils.js'
import { ensureArray } from '@nsf/utils/ArrayUtils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { queryRange, querySearch } from '@nsf/catalog/utils/ElasticQueryUtils.js'

const {
  public: {
    elasticSplittedIndiciesEnabled,
  },
} = useRuntimeConfig()

const {
  rootConfig: {
    elastic: {
      indices: {
        bundleIndex,
        productIndex,
      },
    },
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
} = useAppConfig()

const defaultProductsSort = (q) => q.sortByValues('drmax_pim_status', [['Available', 'Special sale off'], 'Temporary unavailable', 'Visible'])

export const productsQuerySort = (query, sort, filter, emptyFilterCallback = defaultProductsSort) => {
  if (isString(sort)) { // explicit sorting selected by the user
    const [field, order] = sort.split(':')

    query.sort(field, order)
    return 1
  }
  if ((!filter || isEmpty(filter))) { // sort by relevance if nothing else is selected (i.e. filters are empty)
    emptyFilterCallback(query)
    return 0
  }
  // for other filters, show available and special saleoff first
  defaultProductsSort(query)
  return -1
}

export const productsQueryRange = (query, range) => {
  const ranges = ensureArray(range)
  const ageRangeIndex = ranges.findIndex((r) => r.includes('drmax_age_range'))
  if (ageRangeIndex !== -1) {
    const ageRange = ranges.splice(ageRangeIndex, 1)
    const [, min, max] = ageRange[0].split(',')
    query.must((subquery) => {
      subquery.should((q) => q.lessThanEqual('drmax_age_from_value', parseInt(max)).greaterThan('drmax_age_to_value', parseInt(min)))
      subquery.should((q) => q.notExists('drmax_age_from_value').notExists('drmax_age_to_value'))
    })
  }
  queryRange(query, ranges)
}

export const productsQuerySearch = (query, search) => {
  querySearch(query, search, ['name', 'short_description'])
}

export const mapBundlesToProduct = (response) => {
  let bundles
  let product
  if (elasticSplittedIndiciesEnabled) {
    product = response.find((e) => e.index.includes(productIndex)).source
    bundles = response.find((e) => e.index.includes(bundleIndex))
  } else {
    product = response.find((e) => e.type === 'product').source
    bundles = response.find((e) => e.type === 'product_bundle')
  }
  product.bundles = bundles?.source.bundles ?? []
  return product
}

export const productsParamDefaultSortBy = (defaultSortBy, queryParamSort) => {
  if ((defaultSortBy === 'name' || defaultSortBy === 'Product Name') && queryParamSort === undefined) {
    return 'name.keyword:asc'
  } if (queryParamSort === 'relevance:asc') {
    return undefined
  }
  return queryParamSort
}

export const mainPageProductsCount = (eLeader, topProducts, promoProducts) => {
  const mainProducts = eLeader + topProducts + promoProducts
  const offset = productsPerPage - mainProducts

  return !eLeader ? offset - 1 : offset
}
