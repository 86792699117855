/* eslint-disable no-underscore-dangle */
import { Builder } from '@nsf/base/builders/Builder.js'
import { isNullish, isEmpty } from '@nsf/core/Utils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { listingRequiredFields } from '@nsf/catalog/utils/FieldsUtils.js'
import { fetchFilterPrefix, getFilter } from '@nsf/catalog/utils/BuilderUtils.js'
import { productsParamDefaultSortBy, mainPageProductsCount } from '@nsf/catalog/utils/ProductUtils.js'
import { getProductsByCategoryId, getProductsByIds, getProductsBySkus } from '@nsf/catalog/repositories/ProductRepository.js'
import { getCategoryById, getCategoriesByParentId, getCategoriesByPath } from '@nsf/catalog/repositories/CategoryRepository.js'
import placementELeaderLoader from '@nsf/product-relationship-loader-placement/loaders/productData/placementELeaderLoader.js'
import placementTopLoader from '@nsf/product-relationship-loader-placement/loaders/productData/placementTopLoader.js'

const {
  rootConfig: {
    global: {
      pagination: {
        productsPerPage,
      },
    },
  },
  catalog: {
    product: {
      wrapperProductPimFilter,
    },
    category: {
      root,
    },
  },
} = useAppConfig()

const {
  public: {
    placementGenericEnabled,
  },
} = useRuntimeConfig()

// eslint-disable-next-line import/prefer-default-export
export class CategoryBuilder extends Builder {
  #getCategoryId() {
    return this._data?._filterPage?.categoryId || this._id
  }

  getPlacementConfig() {
    const categoryConfig = {
      categoryName: this._data?.name || '',
      categoryId: `${this.#getCategoryId()}`,
      categoryPath: this._data?._filterPage?.categoryPath || this._url?.replace(/^\/+/, ''),
      higherCategoryId: this._data?.path?.split('/').splice(2)
        .join(',') || '',
    }
    return categoryConfig
  }

  async fetchCategory() {
    const { category } = await getCategoryById(this.#getCategoryId())
    return {
      ...category,
      ...(!category?.id && { statusCode: 404 }),
    }
  }

  async fetchPlacementData() {
    try {
      const placementConfig = this.getPlacementConfig()
      const eLeader = placementELeaderLoader.bind({
        ctx: this._ctx,
        config: {
          ...placementConfig,
          limit: 1,
        },
      })
      const topProducts = placementTopLoader.bind({
        ctx: this._ctx,
        config: {
          ...placementConfig,
          limit: 3,
        },
      })
      const [
        { placementData: eLeaderData },
        { placementData: topProductsData },
      ] = await Promise.all([eLeader(), topProducts()])

      return {
        _eLeaderOverride: eLeaderData ?? null,
        _topProductsOverride: topProductsData ?? null,
      }
    } catch (e) {
      return {
        _eLeaderOverride: [],
        _topProductsOverride: [],
      }
    }
  }

  async fetchProducts({ // eslint-disable-line complexity
    eLeaderId,
    _eLeaderOverride,
    _topProductsOverride,
    topProductsIds,
    promoProductsIds,
    defaultSortBy,
    _eLeader,
    _topProducts,
    _promoProducts,
  }) {
    const page = Number(this.query('page')) || 1
    const sort = productsParamDefaultSortBy(defaultSortBy, this.query('sort'))
    const filter = this.getFilter()
    const search = this.query('search')
    const range = this.query('range')

    const without = {}
    const withoutSku = {}
    if (placementGenericEnabled) {
      if (_eLeaderOverride && !(this.getFilter().length || !this.#isDefaultPage())) {
        withoutSku.eLeader = _eLeaderOverride.map((e) => e.productSKU)
      }
      if (_topProductsOverride && !(this.getFilter().length || !this.#isDefaultPage())) {
        withoutSku.topProducts = _topProductsOverride.map((e) => e.productSKU)
      }
    } else {
      if (this._eLeader) {
        without.eLeader = eLeaderId
      }
      if (this._topProducts) {
        without.topProducts = topProductsIds
      }
      if (this._promoProducts) {
        without.promoProducts = promoProductsIds
      }
    }

    const categoryInQuery = this.query('category')

    if (categoryInQuery && categoryInQuery !== 'all') {
      filter.push(`category_ids,${categoryInQuery}`)
    }
    const isMainPage = (page - 1) <= 0

    const eLeaderCount = _eLeader ? 1 : 0
    const topProductsCount = _topProducts?.length || 0
    const promoProductsCount = _promoProducts?.length || 0

    const mainPageProducts = mainPageProductsCount(eLeaderCount, topProductsCount, promoProductsCount)

    const {
      products,
      total,
      query,
      baseQuery,

    } = await getProductsByCategoryId(this.#getCategoryId(), {
      from: isMainPage ? 0 : ((page - 1) * productsPerPage) - (productsPerPage - mainPageProducts),
      sort,
      size: isMainPage ? mainPageProducts : productsPerPage,
      filter,
      search,
      without,
      withoutSku,
      range,
    })

    // If page is out of range available pages we temporarily redirect page to base category path
    if (page > 1 && !products.length) {
      return {
        redirect: this._url,
        statusCode: 302,
      }
    }

    return {
      _products: products,
      _productsTotal: total + eLeaderCount + topProductsCount + promoProductsCount,
      _productsQuery: query,
      _productsBaseQuery: baseQuery,
      _page: page,
    }
  }

  async fetchSubCategories() {
    const { categories } = await getCategoriesByParentId(this.#getCategoryId())

    return { _subCategories: categories }
  }

  async fetchCategories({
    id,
    path,
  }) {
    const { categories } = await getCategoriesByPath(path, {
      except: [id, root.id],
    })

    return { _categories: categories }
  }

  #hasMainProducts(mainProductsIds) {
    if (isNullish(mainProductsIds) || isEmpty(mainProductsIds.filter((tp) => tp))) {
      return false
    }
    if (this.query('category')) {
      return false
    }
    if (this.getFilter().length || this.query('search') || this.query('range')) {
      return false
    }

    return true
  }

  #hasELeader(eLeaderId) {
    if (isNullish(eLeaderId)) {
      return false
    }

    if (this.getFilter().length || this.query('search') || this.query('range')) {
      return false
    }

    return true
  }

  #isDefaultPage() {
    if (this.query('sort')) {
      return false
    }
    if (this.query('search')) {
      return false
    }
    return !this.query('range')
  }

  async #fetchPlacementProducts({
    _eLeaderOverride,
    _topProductsOverride,
  }) {
    const eLeaderSku = _eLeaderOverride?.[0]?.productSKU
    const topProductsSkus = _topProductsOverride?.map((e) => e.productSKU) ?? []
    const allProductsSkus = [eLeaderSku, ...topProductsSkus]

    if (this.getFilter().length || !this.#isDefaultPage()) {
      return {
        _eLeader: null,
        _topProducts: [],
      }
    }
    const { products } = await getProductsBySkus(allProductsSkus,
      {
        size: allProductsSkus.length,
        callback: (query) => {
          query.whereNotIn('drmax_pim_status', wrapperProductPimFilter)
          query.only(listingRequiredFields)
        },
      })

    // eslint-disable-next-line eqeqeq
    const eLeader = products.find((pr) => pr.sku == eLeaderSku) ?? null
    if (eLeader) {
      // eslint-disable-next-line eqeqeq
      eLeader.content = _eLeaderOverride?.find((e) => e.productSKU == eLeader?.sku)?.content ?? {}
    }
    const topProducts = products.filter((pr) => topProductsSkus?.includes(pr.sku) && pr.sku !== eLeaderSku)
    if (topProducts.length) {
      topProducts.forEach((e) => {
        // eslint-disable-next-line eqeqeq
        e.content = _topProductsOverride?.find((f) => f.productSKU == e.sku)?.content ?? {}
      })
    }

    return {
      _eLeader: eLeader,
      _topProducts: topProducts.sort((a, b) => topProductsSkus.indexOf(a.sku) - topProductsSkus.indexOf(b.sku)),
    }
  }

  async #fetchMainProducts({
    topProductsIds = [],
    promoProductsIds = [],
    eLeaderId,
  }) {
    if (!this.#hasMainProducts(topProductsIds)
      && !this.#hasMainProducts(promoProductsIds)
      && !this.#hasELeader(eLeaderId)) {
      return {
        _topProducts: [],
        _promoProducts: [],
      }
    }

    const productsIds = [...(topProductsIds ?? []), ...(promoProductsIds ?? []), ...(eLeaderId ? [eLeaderId] : [])]

    const { products } = await getProductsByIds(productsIds,
      {
        size: productsIds.length,
        callback: (query) => {
          query.whereNotIn('drmax_pim_status', wrapperProductPimFilter)
          query.only(listingRequiredFields)
        },
      })

    let eLeader = null
    const topProducts = []
    const promoProducts = []

    for (const pr of products) {
      if (pr.id === eLeaderId) {
        eLeader = pr
      } else if (topProductsIds.includes(pr.id)) {
        topProducts.push(pr)
      } else if (promoProductsIds.includes(pr.id)) {
        promoProducts.push(pr)
      }
    }
    return {
      _topProducts: topProducts
        .sort((a, b) => ((topProductsIds.indexOf(a.id) <= topProductsIds.indexOf(b.id)) ? -1 : 1)),
      _promoProducts: promoProducts
        .sort((a, b) => ((promoProductsIds.indexOf(a.id) <= promoProductsIds.indexOf(b.id)) ? -1 : 1)),
      _eLeader: eLeader,
    }
  }

  buildHierarchicalCategories({
    path = '',
    _categories,
  }) {
    const ids = path
      .split('/')
      .map((id) => parseInt(id))
      .filter((id) => id !== root.id)
      .filter((id) => id !== this.#getCategoryId())

    const hierarchicalCategories = _categories
      .filter((category) => ids.includes(category.id)) // Filter out categories not in the 'ids' array
      .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)) // Sort categories as they appear in the 'ids' array

    return { _hierarchicalCategories: hierarchicalCategories }
  }

  getFilter() {
    return getFilter(this.query('filter'), this._data)
  }

  async load(loaders = []) {
    if (loaders.length) {
      await super.load(loaders)
    } else {
      await super.load([
        this.fetchCategory,
        fetchFilterPrefix,
      ])
      if (placementGenericEnabled) {
        await super.load([
          this.fetchPlacementData,
        ])
      }
      await super.load([
        placementGenericEnabled ? this.#fetchPlacementProducts : this.#fetchMainProducts,
      ])
      await super.load([
        this.fetchProducts,
        this.fetchCategories,
        this.fetchSubCategories,
      ])

      await super.load([
        this.buildHierarchicalCategories,
      ])
    }
  }
}
