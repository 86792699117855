export default {
  apiKey: 'AIzaSyDA1jERnnza58Fsa8IbY_xfcLpu2trfXH8',
  interfaceLanguage: 'cz',
  defaultLocation: {
    // Na Florenci, Praha
    latitude: 50.0886695,
    longitude: 14.4347974,
    type: 'geolocation',
  },
}
