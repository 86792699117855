const competitionServiceEnabledBasedOnTime = () => {
  const END_TIME = new Date('2024-11-08T23:59:59+01:00')
  return new Date() <= END_TIME
}

export default {
  global: {
    agreementsTopic: 'ESHOP',
    city: 'Praha',
    country: 'Česko',
    langIso: 'cs-CZ',
    countryIso: 'CZE',
    xTenant: 'CZ',
    loqateApiKey: 'ZH66-WZ79-JC37-RU85',
    pagination: {
      productsPerPage: 24,
    },
    pharmacyTypes: ['1', '21'],
    phoneCode: '+420',
    phoneCodes: ['+420', '+421'],
    phoneValidations: ['^\\d{9}$', '^\\d{9}$'],
    storeName: 'Dr.Max',
    validationPhone: '^\\d{9}$',
    zipLength: 5,

    /**
     * Global features - used across multiple common modules
     */
    advancedAgreementsEnabled: true,
    billingInformationAddressEnabled: true,
    companyBillingAddressEnabled: true,
    competitionServiceTimeBasedEnabled: competitionServiceEnabledBasedOnTime(),
    crnEnabled: true,
    fiscalCodeEnabled: false,
    loggedPriceInfoEnabled: true,
    loyaltyCardEnabled: true,
    loyaltyCardPlusPrefixEnabled: false,
    loyaltySavingsEnabled: true,
    multiStoreEnabled: false,
    myAccountCouponsEnabled: true,
    myAccountAmazonEnabled: true,
    myAccountAmazonFromPlacementApi: false,
    myAccountAmazonAnotherOffers: true,
    myAccountOtherOffersButtonEnabled: true,
    newUrlResolverEnabled: true,
    otcFormEnabled: false,
    pecSdiEnabled: false,
    phoneCodeEnabled: true,
    phoneCodeSelectEnabled: true,
    phoneCodeSentSeparatelyEnabled: true,
    regionSelectEnabled: false,
    savingsAndBenefitsInHeaderEnabled: true,
    skDicEnabled: false,
    splitStreetNumberBeforeSavingEnabled: true,
    stockStatusQuantityCheckEnabled: false,
    streetNumberEnabled: false,
    vatIdEnabled: true,
    vatIdRequired: false,

    /**
     * Google Maps API
     * API Keys: https://mydrmax.atlassian.net/wiki/spaces/GLECOM/pages/1600290889/API+keys+-+Google+Maps
     * Supported languages: Subset of ISO 639-1, see https://developers.google.com/maps/faq#languagesupport
     */
    maps: {
      apiKey: 'AIzaSyDA1jERnnza58Fsa8IbY_xfcLpu2trfXH8',
      interfaceLanguage: 'cz',
      myLocationIcon: 'map-my-location.png',
      defaultLocation: {
        latitude: 50.0886695,
        longitude: 14.4347974,
      },
    },

    watchdog: {
      stockAlertEnabled: true,
      priceAlertEnabled: true,
      forceLoginEnabled: false,
    },
  },
}
