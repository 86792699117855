export default {
  agreementsSchema: [
    {
      label: 'offers and announcements to client program',
      description: 'offers and announcements to client program',
      agreements: [
        {
          label: 'Email',
          description: 'loyalty mail agreement description',
          channel: 'EMAIL',
          topic: 'CP',
        },
        {
          label: 'Post',
          description: 'loyalty post agreement description',
          channel: 'POST',
          topic: 'CP',
        },
        {
          label: 'SMS',
          description: 'loyalty sms agreement description',
          channel: 'SMS',
          topic: 'CP',
        },
      ],
    },
    {
      label: 'offers and announcements',
      description: 'offers and announcements',
      agreements: [
        {
          label: 'Email',
          description: 'mail agreement description',
          channel: 'EMAIL',
          topic: 'ESHOP',
        },
        {
          label: 'Post',
          description: 'post agreement description',
          channel: 'POST',
          topic: 'ESHOP',
        },
        {
          label: 'SMS',
          description: 'sms agreement description',
          channel: 'SMS',
          topic: 'ESHOP',
        },
      ],
    }
  ]
}
