export default {
  features: {
    filterableAttributesEnabled: true,
    nsfSlotEnabled: false,
    priceInfoMayhem: false,
    robotsFromConfigEnabled: true,
    watchdogProductPriceNoteEnabled: true,
    watchdogProductPriceLowerBoundValue: 1.0,
  },
}
