import { field, Query, variable } from '@nsf/core/GraphQL.js'
import { REQUIRED_STRING } from '@nsf/core/consts/GraphQLTypes.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import cartItemsModified from '../fragments/cartItemsModified.js'
import cartPrices from '../fragments/cartPrices.js'
import cartAvailablePaymentMethods from '../fragments/cartAvailablePaymentMethods.js'
import cartSelectedPaymentMethod from '../fragments/cartSelectedPaymentMethod.js'
import cartShippingAddress from '../fragments/cartShippingAddress.js'
import cartBillingAddress from '../fragments/cartBillingAddress.js'
import cartAppliedPaymentRestrictions from '../fragments/cartAppliedPaymentRestrictions.js'
import cartBoundProducts from '../fragments/cartBoundProducts.js'
import marketplaceQuote from '../fragments/marketplaceQuote.js'

const {
  checkout: {
    features: { boundProductsEnabled },
  },
} = useAppConfig()

const {
  public: {
    marketplaceEnabled,
  },
} = useRuntimeConfig()

export default () => Query.named('cart')
  .where('cart_id', variable('cartId', REQUIRED_STRING))
  .fields([
    ...cartItemsModified,
    cartPrices,
    field('email'),
    field('order_type'),
    cartBillingAddress,
    cartShippingAddress,
    cartAvailablePaymentMethods,
    cartAppliedPaymentRestrictions,
    cartSelectedPaymentMethod,
    ...boundProductsEnabled ? [cartBoundProducts] : [],
    ...marketplaceEnabled ? [marketplaceQuote] : [],
  ])
