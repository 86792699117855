export default {
  maps: {
    apiDomain: 'https://maps.googleapis.com',
    apiUrl: 'https://maps.googleapis.com/maps/api/js',
    apiVersion: '3.52',
    defaultProps: {
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
    },
    zoomLevels: {
      initial: {
        default: 7,
      },
      geolocation: 7,
    },
    markerClusterer: {
      libraryDomain: 'https://unpkg.com',
      libraryUrl: 'https://unpkg.com/@googlemaps/markerclusterer@2.0.15/dist/index.min.js',
      maxZoom: 14,
      clusterRadius: 120,
      placesLimit: 200,
      clusterFillColors: {
        pharmacy: '#3C951F',
        postOffice: '#F2AC00',
        packeta: '#BA1B02',
        drmax_box: '#3C951F',
        default: '#808080',
      },
    },
  },
}
