import { mapDataToModel } from '@nsf/core/Mapper.js'

export const mapDataToAttribute = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    attribute_code: ['code', String],
    frontend_label: ['label', String],
    frontend_input: ['input', String],
    position: (position) => Number(position) || Infinity,
    options: mapDataToOption,
    ...translations,
  })

  return model
}

export const mapDataToOption = (data, translations = {}) => {
  const model = mapDataToModel(data, {
    value: String,
    label: String,
    ...translations,
  })

  return model
}

export const mapDataToFilterOption = (data, translations = {}) => mapDataToModel(data, {
  value: String,
  label: String,
  attribute_code: String,
  ...translations,
})
