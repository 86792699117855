import { getReviewsByProductId } from '@nsf/catalog/repositories/ReviewRepository.js'
import { getReviews } from '@nsf/catalog/repositories/ReviewServiceRepository.js'
import { setReviewServicePayload } from '@nsf/catalog/mappers/ReviewMapper.js'
import { setState } from '@nsf/core/Store.js'

export const state = () => ({
  reviewData: {
    sku: null,
    total: 0,
    paginationFrom: 0,
    reviews: [],
  },
})

export const mutations = {
  setState,
  addSkuReviews(state, sku) {
    state.reviewData.sku = sku
  },
  setReviews(state, reviews) {
    state.reviewData.reviews = reviews
  },
  addReviews(state, reviews) {
    state.reviewData.reviews.push(...reviews)
  },
}

export const actions = {

  async loadReviews({ commit, state }, {
    id, sku, paginationFrom, sort, range, searchKeyword, params, add,
  }) {
    commit('addSkuReviews', sku)
    const queryParams = {
      from: paginationFrom || 0,
      sort,
      match: searchKeyword,
      range,
      ...params,
    }

    if (this.$config.public.reviewServiceEnabled) {
      const payload = setReviewServicePayload(
        sku,
        paginationFrom,
        sort,
        range,
      )

      const { reviews: allReviews } = await getReviews(payload)
      if (add) {
        commit('addReviews', allReviews)
      } else {
        commit('setReviews', allReviews)
      }
      return
    }

    const { total, reviews } = await getReviewsByProductId(id, queryParams)
    commit('setState', {
      reviewData: {
        sku, reviews: [...state.reviewData.reviews, ...reviews], total, paginationFrom,
      },
    })
  },
}
