import { ORDER_TYPE } from '../../utils/Utils.js'

const orderPickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Objednávku doručíme z' },
      {
        text: 'e-shopu do lékárny,',
        isBold: true,
      },
      {
        embeddedComponent: true,
        data: {
          text1: 'kde poté můžete platit hotově, kartou či',
          text2: 'poukázkami',
        },
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Jakmile bude Vaše objednávka připravená k vyzvednutí, kontaktujeme Vás prostřednictvím SMS a e-mailu.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Objednávku si můžete vyzvednout' },
      {
        text: 'do 5 kalendářních dnů.',
        isBold: true,
      },
    ],
  },
]

const reservationPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Při vyzvednutí' },
      {
        text: 'v lékárně',
        isBold: true,
      },
      { text: 'je možné platit hotově, kartou či poukázkami.' },
      // TODO: implement voucher modal
      // { embeddedComponent: 'v-voucher-payment-options' }, // poukázkami
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Jakmile bude Vaše rezervace připravená k vyzvednutí, kontaktujeme Vás prostřednictvím SMS a e-mailu.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Rezervaci si můžete vyzvednout' },
      {
        text: 'do 4 kalendářních dnů.',
        isBold: true,
      },
    ],
  },
]

const rxPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Při vyzvednutí' },
      {
        text: 'v lékárně',
        isBold: true,
      },
      { text: 'je možné platit hotově, kartou či poukázkami.' },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Jakmile bude Vaše rezervace připravená k vyzvednutí, kontaktujeme Vás prostřednictvím SMS a e-mailu.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Objednávka z e-shopu',
  reservationLabel: 'Rezervace na lékárně',
  rxReservationLabel: 'Rezervace léků na předpis na lékárně',
  unavailablePickupLabel: 'Vyzvednutí na lékárně není k dispozici',
  selectedPlaceLabel: 'Vybraná lékárna',
  confirmButtonLabel: 'Vybrat lékárnu',
  inStockLabel: 'Skladem',
  onOrderLabel: 'Na objednání',
  rxReservationButtonLabel: 'Rezervovat',
  rxOrderButtonLabel: 'Rezervovat na\xa0objednání',
  getTitle() {
    return `Lékárna Dr.Max, ${this.title}`
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions(orderType) {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: (() => {
        switch (orderType) {
          case ORDER_TYPE.RX_ORDER:
          case ORDER_TYPE.RX_RESERVATION:
            return rxPickupInstructions
          case ORDER_TYPE.ORDER:
            return orderPickupInstructions
          case ORDER_TYPE.RESERVATION:
            return reservationPickupInstructions
          default:
            return []
        }
      })(),
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
