export default {
  features: {
    pharmacyFilterEnabled: true,
    detailCmsContentEnabled: true,
    dermocenters: {
      cmsContentEnabled: true,
    },
    pharmacistInfoEnabled: true,
  },
}
