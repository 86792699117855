import ToPageType from '@nsf/product-relationship-loader-placement/mappers/ToPageType.js'
import { RestClient } from '@nsf/core/RestClient.js'
import { getProductsBySkus } from '@nsf/catalog/repositories/ProductRepository.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { isClient } from '@nsf/core/Utils.js'
import { getCookie } from '@nsf/utils/CookieUtils.js'
import logger from '@nsf/product-relationship-loader-placement/logger.js'

const {
  productRelationshipLoaderPlacement: { tenant },
} = useAppConfig()

const {
  public: { placementServiceUrl },
} = useRuntimeConfig()

const client = new RestClient({
  baseURL: placementServiceUrl,
  credentials: 'same-origin',
})

const getLayout = (ctx) => {
  if (!isClient() || !ctx) {
    return 'app'
  }

  const mobile = ctx?.WindowDimensionProvider?.isMobile && 'mobile'
  const tablet = ctx?.WindowDimensionProvider?.isTablet && 'tablet'

  return mobile || tablet || 'desktop'
}

export const getPlacementData = async (
  placement,
  userId,
  {
    categoryName = '',
    productSkus = [],
    categoryId = '',
    categoryPath = '',
    higherCategoryId = '',
    limit = 16,
    pageType = '',
    entityType = 'products',
    apiVersion = 'v2',
  } = {},
  ctx,
) => {
  try {
    const config = {
      headers: {
        'X-Tenant': tenant,
        'Content-Type': 'application/json',
      },
    }

    let marketingConsent = false

    if (isClient()) {
      try {
        marketingConsent = getCookie('CookieConsent')?.includes('marketing:true') ?? false
      } catch (e) {
        logger.error('Error while getting Cookie consent', e)
      }
    }

    const filteredHigherCategoryId = higherCategoryId.split(',').filter((e) => e !== categoryId)
      .join(',')

    const payload = {
      userId,
      productSKUs: productSkus,
      category: {
        categoryName,
        categoryId,
        categoryPath,
        higherCategoryId: filteredHigherCategoryId,
      },
      limit,
      anonymous: !marketingConsent,
      viewId: ctx?.$store?.getters['product-relationship-loader-placement/viewId']
        ?? ctx?.store?.getters['product-relationship-loader-placement/viewId']
        ?? undefined,
      layout: getLayout(ctx),
      platformCode: 'nsf',
      pageType: pageType || ToPageType(placement),
    }

    if (isClient()) {
      const { ok: isOk, data: response } = await client.post(
        `/${apiVersion}/placements/${placement}/${entityType}`,
        payload,
        config,
      )
      if (!isOk) {
        throw new Error(response.message)
      }

      return { placementData: response[entityType] }
    }
    const placementData = await fetch(`${placementServiceUrl}/${apiVersion}/placements/${placement}/${entityType}`, {
      method: 'POST',
      ...config,
      body: JSON.stringify(payload),
    })
    const placementDataJson = await placementData.json()

    return { placementData: placementDataJson[entityType] }
  } catch (e) {
    throw new Error(e)
  }
}

export const getPlacementProducts = async (
  placement,
  userId,
  {
    categoryName = '',
    productSkus = [],
    categoryId = '',
    categoryPath = '',
    higherCategoryId = '',
    limit = 16,
  } = {},
  ctx,
) => {
  try {
    const { placementData } = await getPlacementData(placement, userId, {
      categoryName,
      productSkus,
      categoryId,
      categoryPath,
      higherCategoryId,
      limit,
    }, ctx)

    const productSkusResponse = placementData.map((e) => e.productSKU)

    if (!productSkusResponse?.length) {
      return { products: [] }
    }

    const { products } = await getProductsBySkus(productSkusResponse, {
      size: limit,
      callback: (query) => query.whereIn('drmax_pim_status', ['Available', 'Special sale off']),
    })

    const finalProducts = placementData.flatMap((p) => {
      const product = products.find((item) => item.sku === p.productSKU)
      if (!product) {
        return []
      }
      return {
        ...product,
        content: p?.content ?? {},
      }
    })

    return { products: finalProducts }
  } catch (e) {
    const error = {
      message: e.message ?? 'Unknown error',
      type: placement,
    }

    const store = ctx?.$store ?? ctx?.store
    if (store) {
      store?.dispatch('gtm/setGtmError', error)
    }

    logger.error('getPlacementProducts() failed: %s', e.message)

    return { products: [] }
  }
}

export const getPlacementBanners = async (
  placement,
  userId,
  {
    categoryName = '',
    productSkus = [],
    categoryId = '',
    categoryPath = '',
    higherCategoryId = '',
    limit = 16,
    pageType = '',
  } = {},
  ctx,
) => {
  try {
    let { placementData } = await getPlacementData(placement, userId, {
      categoryName,
      productSkus,
      categoryId,
      categoryPath,
      higherCategoryId,
      limit,
      pageType,
      entityType: 'banners',
      apiVersion: 'v1',
    }, ctx)

    if (!placementData?.length) {
      return { banners: [] }
    }

    placementData = placementData.map((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.content.position = `${(index + 1)}`
      return item
    })

    return { banners: placementData }
  } catch (e) {
    const error = {
      message: e.message ?? 'Unknown error',
      type: placement,
    }

    const store = ctx?.$store ?? ctx?.store
    if (store) {
      store?.dispatch('gtm/setGtmError', error)
    }

    logger.error('getPlacementBanners() failed: %s', e.message)

    return { banners: [] }
  }
}
