/* eslint-disable sort-keys */
export default {
  features: {
    suklWidgetEnabled: true,

    // Cart
    salesRulesEnabled: true,
    fillCartFromUrlParamsEnabled: true,
    cartItemsMaxQtyNotificationEnabled: true,
    cartItemsStockEnabled: true,
    cartItemsAllowBackOrdersEnabled: true,
    cartItemsRowOriginalPriceEnabled: true,
    salesRuleRemovalEnabled: true,
    totalExcludingVatEnabled: true,
    couponsEnabled: true,
    competitionService: {
      competitionWidgetEnabled: true,
    },
    giftCardsEnabled: false,
    freeShippingBoxEnabled: true,
    freeShippingDescriptionEnabled: false,
    freeShippingViaSalesRulesEnabled: true,
    favoriteMethodsInfoBoxEnabled: false,
    boundProductsEnabled: false,
    pickupIdFetchingEnabled: true,
    attributeSetEnabled: true,
    setCartIdFromUrlParamsEnabled: true,
    marketplaceGiftCardsDisabledForPure3p: false,
    deliveryTimesPerProductEnabled: false,

    // Shipping and payment
    orderTypeEnabled: true,
    otcOrderPreferenceEnabled: true,
    reservationsWithRxProductsEnabled: false,
    deliveryFeEnabled: true,
    deliveryRouteIdEnabled: true,
    paymentFeeEnabled: true,
    vouchersModalEnabled: true,
    removeCartItemsModalEnabled: true,
    reservationInfoBoxEnabled: true,
    reservationsIgnoreStockStatusEnabled: false,
    reservationCheckOpeningHoursEnabled: false,
    pickupPlaceCoordinatesEnabled: true,
    pickupPlaceAddressEnabled: true,
    pickupPlaceOpeningHoursEnabled: true,
    pickupPlaceExtensionAttributesEnabled: true,
    timeSlotDeliveryEnabled: true,
    shippingMethodsGo4Enabled: false,
    allowSelectFullBox: false,
    rxOnlyInReservationsEnabled: false,
    useDtcForBoxes: true,
    usePickupSelectV2: false,
    useCommonMap: false, // don't merge true to master, for dev only
    isDrmaxBoxUpdatedFromPickupPlaces: false,
    simpleDeliveryTimeEnabled: true,
    withPickupTimes: true,

    // Address forms
    fillFormsFromLogin: true,
    persistFormsInLocalStorage: false,
    phoneSlicingEnabled: true,
    streetNumberToMagentoEnabled: false,
    shippingAddressNameEnabled: false,
    countrySelectEnabled: true,
    countrySelectChoiceEnabled: true,
    companyDataPrefillEnabled: true,
    privateInvoiceEnabled: false,
    crnAndVatIdValidationOnlyForDomesticOrdersEnabled: true,
    billingAddressSubheadingEnabled: false,
    optionalBillingInfoForCACOrderEnabled: true,
    optionalBillingInfoForCACReservationEnabled: false,
    customerNotesEnabled: true,
    removeSpacesFromZipForMagentoEnabled: true,
    disableB2BForMarketplace: false,

    // Address validation
    addressValidationEnabled: true,

    // Agreements
    cartAgreementsEnabled: true,
    emailSmsAgreementEnabled: true,
    heurekaAgreementEnabled: true,
    personalDataAgreementEnabled: false,
    priceComparisonAgreementEnabled: true,
    staffinoAgreementEnabled: false,
    agreeToPayNoteEnabled: true,

    // Placing orders
    drmaxGatewayMethodEnabled: true,
    gtmOrderInfoGqlEnabled: true,
    placeOrderDeliveryCalculationEnabled: true,
    drmaxCjEventEnabled: true,
    extraDataInPlaceOrderEnabled: true,
    setPostCodeForPacketaBeforeOrderEnabled: true,

    // Thank you pages
    payUSuccessCheckingEnabled: true,
    extraOfferEnabled: true,
    sponsoredProductsLabelEnabled: true,
    dataLayerEventForCeneoEnabled: false,
    nsfSlotEnabled: false,
    bankTransferDetailsDisplayEnabled: false,
  },
}
