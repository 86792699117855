import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  defaultLocale: 'cz',
  locales: [
    {
      code: 'cz',
      domain: '',
      file: 'index.js',
      iso: 'cs-CZ',
    },
  ],
  vueI18n: {
    dateTimeFormats: {
      'cs-CZ': {
        short: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        },
        long: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric',
        },
      },
    },
  },
  ...config,
})
