export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      pickup_place_cc: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém se zpracováním',
          text: 'Prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a> a podíváme se, co se stalo s Vaší objednávkou / rezervací.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čeká na naskladnění',
          text: 'Prosím, vyčkejte, jakmile bude produkt znovu naskladněn a objednávka / rezervace bude zprocesována.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Připravená na odeslání',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odeslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'K vyzvednutí',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Expirovaná',
          text: 'Objednávka / rezervace nebyla vyzvednuta včas. Pro více informací, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Vrací se zpět na sklad',
          text: 'Objednávka nebyla doručena a zboží je na cestě zpět do skladu. Pro více informací, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      pickup_place_box: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nezaplacená',
          text: 'Prosím, zkontrolujte si e-mailovou schránku (včetně SPAM složky) a proveďte platbu objednávky/rezervace.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplacená',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém se zpracováním',
          text: 'Prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a> a podíváme se, co se stalo s Vaší objednávkou / rezervací.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čeká na naskladnění',
          text: 'Prosím, vyčkejte, jakmile bude produkt znovu naskladněn a objednávka / rezervace bude zprocesována.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Připravená na odeslání',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odeslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'K vyzvednutí',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Expirovaná',
          text: 'Objednávka / rezervace nebyla vyzvednuta včas. Pro více informací, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Vrací se zpět na sklad',
          text: 'Objednávka nebyla doručena a zboží je na cestě zpět do skladu. Pro více informací, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      delivery: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nezaplacená',
          text: 'Prosím, zkontrolujte si e-mailovou schránku (včetně SPAM složky) a proveďte platbu objednávky/rezervace.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplacená',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém se zpracováním',
          text: 'Prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a> a podíváme se, co se stalo s Vaší objednávkou / rezervací.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čeká na naskladnění',
          text: 'Prosím, vyčkejte, jakmile bude produkt znovu naskladněn a objednávka / rezervace bude zprocesována.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Připravená na odeslání',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odeslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Vrací se zpět na sklad',
          text: 'Objednávka nebyla doručena a zboží je na cestě zpět do skladu. Pro více informací, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      reservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém se zpracováním',
          text: 'Prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a> a podíváme se, co se stalo s Vaší objednávkou / rezervací.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'K vyzvednutí',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      rxreservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém se zpracováním',
          text: 'Prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a> a podíváme se, co se stalo s Vaší objednávkou / rezervací.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čeká na naskladnění',
          text: 'Prosím, vyčkejte, jakmile bude produkt znovu naskladněn a objednávka / rezervace bude zprocesována.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      delivery_mp: [
        {
          type: 'milestone',
          key: 'inprogress_payment_complete',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplacená',
          text: '',
          variant: 'success',
          parent: 'inprogress_payment_complete',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Ve zpracování',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odeslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      pharmacy_purchase: [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvořená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Převzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Pokud se chcete dozvědět více informací nebo Vám není něco zřejmé, prosím, kontaktujte naši Klientskou linku (v pracovní dny od 8:00 do 17:00) na čísle <a href="tel:+420 516 770 100">+420 516 770 100</a> nebo e-mailu <a href="mailto:info@drmax.cz">info@drmax.cz</a>.',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
