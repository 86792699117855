import { setState } from '@nsf/core/Store.js'
import {
  isAdultContentAllowed,
  setAdultsOnlyAllowed,

} from '@nsf/adults-only/repositories/AdultsOnlyRepository.js'

export const state = () => ({
  confirmAgeModalVisible: false,
})

export const getters = {
  confirmAgeModalVisible: (state) => state.confirmAgeModalVisible,
}

export const mutations = {
  setState,
}

export const actions = {
  showConfirmAgeModal({ commit }) {
    commit('setState', { confirmAgeModalVisible: true })
  },

  hideConfirmAgeModal({ commit }) {
    commit('setState', { confirmAgeModalVisible: false })
  },

  checkAdultsOnly({ commit }) {
    if (!isAdultContentAllowed()) {
      commit('setState', { confirmAgeModalVisible: true })
    }
  },

  allowAdultContent({ commit }) {
    setAdultsOnlyAllowed()
    commit('setState', { confirmAgeModalVisible: false })
  },
}
