export default {
  carrierCodes: {
    clickAndCollect: {
      pickupPlaceCarrierCode: 'drmaxpickupplace',
      packetaCollectCarrierCode: 'drmaxshippingpacketa',
      pharmacyCollectCarrierCode: 'drmaxclickandcollectshipping',

      drmaxBoxHookCode: 'drmax_box',
      pharmacyHookCode: 'pharmacy',
    },
    limitedByZipCode: {},
    timeSlotDelivery: {
      timeSlotDeliveryCarrierCode: 'drmaxtimeslotdelivery',
    },
  },
}
