import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

const validateCzVAT = (value) => {
  const digitsOnly = /^[0-9]*$/.test(value)
  const inputDigits = value.split('')
  const checkSum = inputDigits.slice(0, 7)
    .reduce(
      (accumulator, digit, index) => accumulator + (8 - index) * +digit, 0,
    )
  const checkSumRemainder = checkSum % 11
  const checkDigit = (11 - checkSumRemainder) % 10
  return digitsOnly && +inputDigits[7] === checkDigit
}

export default defineAppConfig({
  cityMaxLength: 50,
  companyNameMaxLength: 70,
  emailMaxLength: 50,
  firstNameMaxLength: 25,
  lastNameMaxLength: 40,
  streetMaxLength: 50,

  cityAllowedCharacters: () => true,
  cityContainsLetter: () => true,
  customerNotesAllowedCharacters: () => true,
  firstLastNameAllowedCharacters: (value) => /^[a-zA-Z-'\s\u00C0-\u00FF\u0100-\u017F]*$/.test(value),
  streetAllowedCharacters: () => true,

  crnValid: () => true,
  crnNumbersOnly: (value) => /^[0-9]+$/.test(value),
  crnLengthValid: (value) => value.length === 8,
  crnLength: 8,

  passwordForbiddenKeywords: ['heslo'],

  postCodeValid: (value) => /^[0-9]{3}\s?[0-9]{2}$/.test(value),

  streetValid: (value) => /^(.*[^0-9]+)\s(([1-9][0-9]*)\/)?([1-9][0-9]*[a-zA-Z]?)$/.test(value),
  streetNumberValid: () => true,
  streetNumberContainsNumber: () => true,

  validByFirstThreeDigits: () => true,
  validByForbiddenCode: () => true,

  vatIdMinLengthValid: (value) => value.length >= 10,
  vatIdMaxLengthValid: (value) => value.length <= 12,
  vatIdForbiddenWords: () => true,
  vatIdStartsWith: (value) => value.substring(0, 2) === 'CZ',
  vatIdMinLength: 10,
  vatIdMaxLength: 12,
  vatIdValid(value) {
    if (/^CZ[0-9]{8,10}$/.test(value) || /^CZ699[0-9]{6}$/.test(value)) {
      return true
    }

    if (value.length === 10) {
      // eslint-disable-next-line no-use-before-define
      return validateCzVAT(value.substring(2))
    }

    return true
  },
})
