export default {
  tags: [
    {
      image: 'akce-eshop',
      key: 'akce-a-slevy-v-eshopu',
      link: '/akce/akce-a-slevy-v-eshopu',
      title: 'Akce v e-shopu',
    },
    {
      image: 'vyprodej',
      key: 'akcni-vyprodej',
      link: '/akcni-vyprodej',
      title: 'Výprodej',
    },
    {
      image: '1a1-navic',
      key: 'nabidka-1-1-zdarma',
      link: '/nabidka-1-1-zdarma',
      title: '1+1 navíc',
    },
    {
      image: '3za2',
      key: 'nabidka-3-za-cenu-2',
      link: '/nabidka-3-za-cenu-2',
      title: '3 za cenu 2',
    },
    {
      image: 'darek-navic',
      key: 'nabidka-s-darkem',
      link: '/nabidka-s-darkem',
      title: 'S dárkem navíc',
    },
    {
      image: 'specialni-nabidky',
      key: 'specialni-nabidky',
      link: '/specialni-nabidky',
      title: 'Speciální nabídky',
    },
    {
      image: 'vsechny',
      key: 'vsechny-akce-a-slevy',
      link: '/vsechny-akce-a-slevy',
      title: 'Všechny slevy',
    },
  ],
}
