import { defineNuxtConfig } from '@nsf/use/composables/defineNuxtConfig.js'

export default defineNuxtConfig({
  runtimeConfig: {
    public: {
      reviewServiceEnabled: false,
      reviewServiceUrl: '',
    },
  },
})
